import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Accordion from '../../../components/Accordion.js'
import Layout from '../../../components/layout'

const NorthAll = ({data}) => {
    return <Layout>
        <Helmet>
            <title>NORTH INDIAN PANDIT IN Horamavu, BANGALORE (15+yrs Exp)</title>
            <meta name="description" content="Looking for North Indian Pandit in Horamavu, Bangalore? We provide the best Hindi Speaking Pandit in Bangalore, India for Marriage, Wedding, Grihapravesham and others poojas. We also Provide Vedic Hindu priests &amp; Kumaoni, Kashmiri Pandits." />
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">NORTH INDIAN PANDIT IN Horamavu, BANGALORE (15+yrs Exp)</h1>
                        <h1 className="mobile">NORTH INDIAN PANDIT IN Horamavu, <br/>BANGALORE(15+yrs Exp)</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Looking for North Indian Pandit in Horamavu, Bangalore? We provide the best Hindi Speaking Pandit in Bangalore, India for Marriage, Wedding, Grihapravesham and others poojas. We also Provide Vedic Hindu priests &amp; Kumaoni, Kashmiri <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Pandits</a>.</p>
                    <Accordion
          id="1"
          question=" more"
          answer="
<h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>Would the Puja be performed as per the North Indian traditions?
<span>Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from various parts of North India such as UP, Uttarakhand, Bihar, Jharkhand, West Bengal etc. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title_hindi}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order_north}) {
    edges {
      node {
        frontmatter {
          order_north
          title
          title_hindi
          experience
          degree
          regions
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Hem Chandra Joshi", "Pandit TPrashanth Pandey", "Pandit Subodh Pandey", "Pandit Ashutosh Pandey", "Pandit TSudharkar Pandey", "Pandit UDiwakar Pandey"]}}, sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default NorthAll